import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Box, Text, Card, Flex } from 'theme-ui'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, `wrap`, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, `full`, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)

  const { photo } = data
  const { fluid } = (photo && photo.childImageSharp) || {}

  return (
    <>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About Me'
            subheader='모든 정보를 알기 쉽게 알려드리고 싶습니다.'
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img fluid={fluid} />
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='My Story'>
                <Text variant='p'>
                  그누보드4를 시작으로 그누보드5로 홈페이지를 운영하고 있습니다.
                </Text>
                <Text variant='p'>
                  포고플러그로 그누보드4를 돌리다가 WD My book live, My cloud, Odroid U2를 거쳐 홈서버로 운영 중입니다.
                </Text>
                <Text variant='p'>
                  Nginx 웹서버를 좋아합니다. 그래서 ghost, 워드프레스, 그누보드를 Nginx로 돌리고 있습니다.
                </Text>
                <Text variant='p'>
                  홈서버를 운영하면서 알게된 정보들을 알기 쉽게 알려드리는 것이 행복합니다.
                </Text>
                <Text variant='p'>
                  <ul>
                    <li>도메인</li>
                    <li>서버 및 클라우드</li>
                    <li>CMS</li>
                    <li>Gatsby</li>
                    <li>Docker</li>
                  </ul>
                </Text>
                <Text variant='p'>
                  현재 공부하고 있는 것들입니다. 그누보드에 Vditor라는 중국산 에디터에 관심이 있어 그누보드5.4에 올리는 가이드 작업을 하였습니다.
                </Text>

                <Text variant='p'>
                  그리고 Gatsby를 이용하여 이 블로그를 꾸미고 있으며, 더 공부하고 있습니다.
                </Text>
                <Text variant='p'>
                  마지막으로 Docker를 활용하여 실사용 수준에 가깝게 구현하는 방법에 대한 가이드를 적고 있습니다.
                </Text>
              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title='Nginx'>
                <Card variant='paper'>
                  Nginx는 정적파일 전송에 최적화되어 있습니다. Apache가 많이 쓰이지만, 개인용도로 Nginx도 경쟁력이 충분하다고 생각합니다. 워드프레스 캐시 플러그인과 호환이 잘 되어 빠른 속도를 보여줍니다.
                </Card>
              </Section>
              <Divider />
              <Section title='Gatsby'>
                <Card variant='paper'>
                  정적사이트 생성기인 Gatsby는 수많은 plugin으로 초보자들도 손쉽게 수준 높은 블로그를 만들 수 있게 도와줍니다. 그리고 다양한 테마를 이용하여 사용자가 원하는대로 만들 수 있습니다.
                </Card>
              </Section>
              <Divider />
              <Section title='Docker'>
                <Card variant='paper'>
                  Docker compose를 사용하여 매우 편하게 웹 환경을 만들 수 있습니다. 만들어진 이미지를 활용하여 언제든지 쉽게 만들 수 있고, 어디에서나 같은 환경을 만들 수 있어 편리합니다.
                </Card>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </>
  )
}

const aboutQuery = graphql`
  query AboutQueryshadowing {
    photo: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        fluid(maxWidth: 1140, maxHeight: 500, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid_noBase64
          width: presentationWidth
          height: presentationWidth
        }
      }
    }
  }
`
